<template>
    <div>
        <h1 class="page__title">Баннеры</h1>

        <div class="actions__container">
            <router-link class="btn btn-primary w-40 inline-block mr-1 mb-2" :to="{ name: 'content-banners-create' }">
                Создать
            </router-link>
        </div>

        <ContentSearch />

        <ContentTable
            :loading="loading"
            :headers="tableExample"
            :table-rows="tableData"
            :meta="pagination"
            :table-actions="tableActions"
            @edit="editAction"
            @delete="deleteAction"
        />

        <PopupDeleteModal
            :is-open="modalDeleteOpen"
            :entity-name="modalDeleteCaption"
            :modal-data="modalDeleteRow"
            @update:isOpen="modalDeleteOpen = $event"
            @deleteModalAccepted="onDeleteModalAccepted"
        />
    </div>
</template>

<script>
import { errorResponse } from '@/mixins/form/form-mixin';
import ContentTable from '@/components/tables/ContentTable';
import ContentSearch from '../../../components/base/ContentSearch';
import PopupDeleteModal from '@/components/modals/PopupDeleteModal';
import { pageMixin } from '@/mixins/pageMixin';
export default {
    name: 'ContentPage',
    components: { ContentSearch, ContentTable, PopupDeleteModal },
    mixins: [errorResponse, pageMixin],
    async beforeRouteUpdate(to, from, next) {
        await this.fetchData({ ...to.query });
        next();
    },
    data() {
        return {
            loading: false,
            modalOpen: false,
            tableExample: [
                { field: 'id', label: '#' },
                { field: 'title', label: 'Название' },
                { field: 'code', label: 'Код' },
            ],
            tableActions: [
                { name: 'edit', title: 'редактировать', icon: 'Edit2Icon', cbName: 'edit' },
                { name: 'delete', title: 'удалить', class: 'bg-red-400', icon: 'Trash2Icon', cbName: 'delete' },
            ],
            tableData: null,
            pagination: null,
            modalType: null,
            modalTitle: null,
            modalCallback: null,
            modalDeleteOpen: false,
            modalDeleteCaption: null,
            modalDeleteRow: null,
        };
    },
    async created() {
        await this.fetchData(this.$route.query);
    },
    methods: {
        async fetchData(params) {
            this.loading = true;

            try {
                const { data } = await this.axios.get('/banners', { params });

                this.tableData = data.data;
                this.pagination = data.meta;
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant fetch content pages: ' + ex);
            }
        },
        deleteAction(row) {
            this.modalDeleteOpen = true;
            this.modalDeleteCaption = 'слайдер баннеров';
            this.modalDeleteRow = row;
        },
        editAction(row) {
            this.modalType = 'update';
            // this.$router.push({ name: 'content-banners-edit', params: { id: row.id } });
            this._pMTransitionToEditPageWithQuery('content-banners-edit', { id: row.id });
        },
        onDeleteModalAccepted(data) {
            this.axios
                .delete('/banners/' + data.id)
                .then((res) => {
                    this.fetchData();
                    this.modalOpen = false;
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
    },
};
</script>

<style scoped></style>
